import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Comments from "./Comments";
import Interested from "./Interested";
import { GETREQUEST } from "../../store/actionCreators";

const BlogPost = () => {
  const { url } = useParams(); // Get URL param from route
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Get blog posts from the Redux store
  const blogPosts = useSelector((state) => state.Blogs);

  useEffect(() => {
    const fetchData = async () => {
      // First, check if the blog post is already in the Redux store
      const postFromStore = blogPosts?.find(
        (post) => post.generatedUrl === url,
      );
      setLoading(true);

      if (postFromStore) {
        setData(postFromStore); // Set data from store
        setLoading(false); // Stop loading
      } else {
        // Fetch the post from the API if not found in Redux
        const response = await GETREQUEST(`blogpost/${url}`);
        if (response) {
          setData(response); // Set data from API response
          setLoading(false); // Stop loading
        }
      }
    };

    fetchData();
  }, [url, blogPosts]); // Re-run when URL or blogPosts change

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [url]);

  if (loading) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95vh",
          }}
        >
          <Spin indicator={<LoadingOutlined />} size="large" tip="Loading..." />
        </div>
        <Footer mode="simple" style={{ position: "absolute", bottom: 0 }} />
      </>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{data?.title || "Blog Post"}</title>
      </Helmet>
      <Header />
      <div className="layout">
        <div className="layout__content">
          <div className="singleBlog">
            {data ? (
              <>
                <h2 className="singleBlog__title">{data.title}</h2>
                <div className="singleblog-container">
                  <div
                    className="singleBlog__content"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  ></div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin indicator={<LoadingOutlined />} tip="Loading..." />
              </div>
            )}
            <Interested parent="Blogs" />
            {data && data._id && (
              <Comments
                parentId={data._id}
                parentType="blog"
                parentTitle={data.title}
              />
            )}
          </div>
        </div>
        <div className="layout__sidebar">
          <Sidebar />
        </div>
      </div>

      <Footer mode="simple" />
    </div>
  );
};

export default BlogPost;
