import React from "react";
import { connect } from "react-redux";

import DynamicImages from "./shared/DynamicImages";
import { Link } from "react-router-dom";

class Interested extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { Blogs, Videoblogs, Courses, parent } = this.props;
    return (
      <div className="interested">
        <h3 className="interested__title">ՁԵԶ ԿՀԵՏԱՔՐՔՐԻ ՆԱԵՎ…</h3>
        <div className="interested__items">
          {parent &&
            parent === "Videoblogs" &&
            Videoblogs &&
            Videoblogs.slice(0, 3).map((item, key) => {
              return (
                <div key={key} className="interested__items__item">
                  <Link to={item.generatedUrl}>
                    <DynamicImages url={item.imageUrl} />
                    {/* <img src={item.imageUrl} alt='videblog' /> */}
                  </Link>
                  <Link to={item.generatedUrl}>
                    <p className="interested__items__item__link">
                      {item.title}
                    </p>
                  </Link>
                </div>
              );
            })}
          {parent &&
            parent === "Blogs" &&
            Blogs &&
            Blogs.slice(0, 3).map((item, key) => {
              return (
                <div key={key} className="interested__items__item">
                  <Link to={item.generatedUrl}>
                    <DynamicImages url={item.imageUrl} />
                    {/* <img src={item.imageUrl} alt='blogs'/> */}
                  </Link>
                  <Link to={item.generatedUrl}>
                    <p className="interested__items__item__link">
                      {item.title}
                    </p>
                  </Link>
                </div>
              );
            })}
          {parent &&
            parent === "Courses" &&
            Courses &&
            Courses.slice(0, 3).map((item, key) => {
              return (
                <div key={key} className="interested__items__item">
                  <Link to={item._id}>
                    <DynamicImages url={item.imageUrl} />
                    {/* <img src={item.imageUrl} alt='courses'/> */}
                  </Link>
                  <Link to={item._id}>
                    <p className="interested__items__item__link">
                      {item.title}
                    </p>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const get = (state) => {
  return {
    Blogs: state.Blogs,
    Videoblogs: state.Videoblogs,
    Courses: state.Courses,
  };
};

export default connect(get)(Interested);
