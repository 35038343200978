import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Comments from "./Comments";
import Interested from "./Interested";
import DynamicImages, { mainRoute } from "./shared/DynamicImages";
import { GETREQUEST } from "../../store/actionCreators";
import { getSingleCourse } from "../../store/api";
import SocialIcons from "./shared/SocialIcons";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SingleCourse = () => {
  const { id } = useParams(); // Get course ID from URL params
  const dispatch = useDispatch();
  const Courses = useSelector((state) => state.Courses);
  const SiteInfo = useSelector((state) => state.SiteInfo);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check if the course data is already in the Redux store
  useEffect(() => {
    const fetchData = async () => {
      const courseFromRedux = Courses?.find((course) => course._id === id);

      if (courseFromRedux) {
        setLoading(true);
        await sleep(150);
        setData(courseFromRedux); // Use data from the store
        setLoading(false);
      } else {
        const response = await GETREQUEST(getSingleCourse(id));
        if (response.code === 200) {
          setData(response.data);
        }
        setLoading(false); // Stop loading after fetching
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  // Ensure map loads after component mounts
  useEffect(() => {
    const metaDatas = document.getElementsByTagName("code");
    Array.prototype.map.call(metaDatas, (item, key) => {
      if (item.innerHTML === "map") {
        const childElement = document.createElement("div");
        childElement.className = "map-div";
        item.innerHTML = "";
        childElement.innerHTML = `<iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1815.4368315121103!2d44.50687889210344!3d40.17057009133478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abc597a0402c5%3A0xf77ed062854e1e83!2sPress%20Building!5e0!3m2!1sru!2s!4v1578166442313!5m2!1sru!2s"
          width="873"
          height="280"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          style={{ width: "80%" }}
        ></iframe>`;
        item.appendChild(childElement);
      }
    });
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95vh",
          }}
        >
          <Spin indicator={<LoadingOutlined />} size="large" tip="Loading..." />
        </div>
        <Footer mode="simple" style={{ position: "absolute", bottom: 0 }} />
      </>
    );
  }

  if (!data) {
    return (
      <>
        <Header />
        <div>No course data available</div>
        <Footer mode="simple" />
      </>
    );
  }

  const {
    phone,
    viber,
    email,
    skype,
    address,
    facebook,
    telegram,
    youtube,
    linkedin,
  } = SiteInfo ? SiteInfo[0] : {};

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
        <link
          rel="preload"
          as="image"
          href={`${mainRoute}${data.captionUrl}`}
        />
      </Helmet>

      <Header />

      <div className="layout">
        <div className="layout__content single-lesson-wrap">
          <h2 className="singleLesson__title">{data.title}</h2>
          <div style={{ width: "100%", height: "250px" }}>
            <DynamicImages
              url={data.captionUrl}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>

          <div className="singleLesson">
            <div
              className="singleLesson__content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>
          </div>

          <div className="grancvel">
            <Link
              className="register-for-lesson"
              to="/register"
              style={{ marginBottom: 20, marginTop: 20 }}
            >
              <i className="fa fa-user-plus" style={{ color: "white" }}></i>
              ԳՐԱՆՑՎԵԼ
            </Link>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <br />
            <div className="feedback-first-line singleblog-content">
              <blockquote>ՈՒՆԵ՞Ք ՀԱՐՑԵՐ</blockquote>
              <div className="footer-contact first-line footer-contact-single-course ">
                <div className="footer_phone fc_item">
                  <i
                    className="material-icons md-36"
                    style={{ color: "#217142" }}
                  >
                    phone
                  </i>
                  Հեռախոս
                  <br />
                  <p className="feedback-footer-info">{`+ ${phone}`}</p>
                </div>
                <div className="footer_viber fc_item ">
                  <i
                    className="fa fa-phone-square fa-2x"
                    style={{ color: "#217142" }}
                  ></i>
                  Viber
                  <br />
                  <p className="feedback-footer-info">{`+ ${viber}`}</p>
                </div>
              </div>
              <div className="footer-contact footer-contact-single-course">
                <div className="footer_mail fc_item">
                  <i
                    className="fa fa-envelope fa-2x"
                    style={{ color: "#217142" }}
                  ></i>
                  Էլ. փոստ
                  <br />
                  <p className="feedback-footer-info">{`${email}`}</p>
                </div>
                <div
                  className="footer_skype fc_item"
                  style={{ marginRight: "19px" }}
                >
                  <i
                    className="fa fa-skype fa-2x"
                    style={{ color: "#217142" }}
                  ></i>
                  Skype
                  <br />
                  <p className="feedback-footer-info">{`${skype}`}</p>
                </div>
              </div>
              {address && address.active && (
                <div className="footer-contact-address">
                  <i
                    className="fa fa-map-marker fa-2x"
                    style={{ color: "#217142" }}
                  ></i>
                  Հասցե
                  <br />
                  <p className="feedback-footer-info">{address.name}</p>
                </div>
              )}
              <SocialIcons
                style={{ width: "160px" }}
                facebook={facebook}
                telegram={telegram}
                youtube={youtube}
                linkedin={linkedin}
                viber={viber}
              />
            </div>
          </div>

          <Interested parent="Courses" />

          {data._id && (
            <Comments
              parentId={data._id}
              parentType="course"
              parentTitle={data && data.title}
            />
          )}
        </div>
        <div className="layout__sidebar">
          <Sidebar />
        </div>
      </div>

      <Footer mode="simple" />
    </>
  );
};

export default SingleCourse;
