import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Comments from "./Comments";
import Interested from "./Interested";
import { GETREQUEST } from "../../store/actionCreators";
import { getSinglepost } from "../../store/api";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { sleep } from "./SingleCourse";

function replaceURLs(message) {
  if (!message) return "";
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  if (message.includes("<a")) {
    return message;
  }
  return message.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

const createMarkup = (text) => ({ __html: replaceURLs(text) });

function getId(url) {
  if (!url) return null;
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

const VideoPost = () => {
  const { url } = useParams(); // Get URL param
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const VideoPosts = useSelector((state) => {
    return state.Videoblogs;
  });
  console.log("VideoPosts", VideoPosts);

  useEffect(() => {
    const fetchData = async () => {
      const postFromStore = VideoPosts?.find(
        (post) => post.generatedUrl === url,
      );

      if (postFromStore != null) {
        setLoading(true);
        await sleep(150);
        setData(postFromStore);
        setLoading(false);
      } else {
        const response = await GETREQUEST(getSinglepost(url));
        setData(response);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, VideoPosts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [url]);

  if (loading) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95vh",
          }}
        >
          <Spin indicator={<LoadingOutlined />} size="large" tip="Loading..." />
        </div>
        <Footer mode="simple" style={{ position: "absolute", bottom: 0 }} />
      </>
    );
  }

  const title = data.title && `${data.title} | Excelist.am`;

  return (
    <div className="videopost-container">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <div className="layout singleVideoblog">
        <div className="layout__content">
          <h2 className="singleVideoblog__title">{data.title}</h2>
          {data.video_link && (
            <div>
              <iframe
                allowFullScreen
                mozallowfullscreen
                msallowfullscreen
                oallowfullscreen
                webkitallowfullscreen
                title="Video"
                className="singleVideoblog__iframe"
                src={`//www.youtube.com/embed/${getId(data.video_link)}`}
              />
            </div>
          )}
          <div>
            <p>
              {data.language === "rus" ? (
                <span>Подпишитесь /Subscribe/ </span>
              ) : (
                <span>Բաժանորդագրվե’ք /Subscribe/</span>
              )}
              <strong>
                <a
                  href="https://www.youtube.com/c/MsExcelOnlineLessons"
                  className="green-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.language === "rus" ? (
                    <span>на наш канал.</span>
                  ) : (
                    <span>մեր յութուբյան ալիքին</span>
                  )}
                </a>
              </strong>
              ։
            </p>
            {data.file_link && (
              <div>
                <p>
                  {data.language === "rus" ? (
                    <span> Для получения файла, заполните </span>
                  ) : (
                    <span>Հոլովակի ֆայլը ստանալու համար՝ լրացրե՛ք </span>
                  )}
                  <NavLink to={`/filerequest/:${data.title}`}>
                    <span className="singleVideoblog__formtext">
                      {data.language === "rus" ? " форму" : " ֆորման"}
                    </span>
                  </NavLink>
                  :
                </p>
              </div>
            )}
            {data.about_video && (
              <div className="about_video_text">
                <p dangerouslySetInnerHTML={createMarkup(data.about_video)} />
              </div>
            )}
            {data.is_macrolab && (
              <div>
                Մանրամասների համար դիմե՛ք․
                <br />
                🌐 www.macrolab.am
                <br />
                📞 Tel: 093 18 88 95,
                <br />✉ E-mail: info@macrolab.am
              </div>
            )}
          </div>
          <Interested parent="Videoblogs" />
          {data._id && (
            <Comments
              parentId={data._id}
              parentType="videoblog"
              parentTitle={data.title}
            />
          )}
        </div>
        <div className="layout__sidebar">
          <Sidebar />
        </div>
      </div>
      <Footer mode="simple" />
    </div>
  );
};

export default VideoPost;
