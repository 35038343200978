import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
// import * as serviceWorker from "./serviceWorker";

//Redux modules
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import Store from "./store/reducers";

const store = createStore(Store, compose(applyMiddleware(thunk)));
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root"),
);
// ReactDOM.render(<App/>, document.getElementById('root'))
// serviceWorker.unregister();
